export const COOKIE_CONSENT_NAME = 'cookie-consent';
export const COOKIE_NEWS_DISMISSED_NAME = 'news-dismissed';
export const COOKIE_CART_NAME = 'cart';
export const COOKIE_CONSENT_VERSION = 1;
export const COOKIE_CONSENT_UNCHECKED = 0;
export const COOKIE_CONSENT_PENDING = 1;
export const COOKIE_CONSENT_SET = 2;
export const COOKIE_CONSENT_FUNCTIONAL = 'fc';
export const COOKIE_CONSENT_ANALYTICS = 'an';
export const COOKIE_CONSENT_MARKETING = 'mk';
export const COOKIE_SETTINGS = {
  maxAge: 60 * 60 * 24 * 365, // 1 year
  sameSite: 'strict',
  path: '/',
  secure: process.env.ENVIRONMENT !== 'development',
};
