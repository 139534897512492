import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4c55b695 = () => interopDefault(import('../pages/homepage.vue' /* webpackChunkName: "pages/homepage" */))
const _1a194f72 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _3eb0c7ed = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _4f36d431 = () => interopDefault(import('../pages/productOverview.vue' /* webpackChunkName: "pages/productOverview" */))
const _e57bd7e4 = () => interopDefault(import('../pages/recipeOverview.vue' /* webpackChunkName: "pages/recipeOverview" */))
const _3bb87777 = () => interopDefault(import('../pages/storyOverview.vue' /* webpackChunkName: "pages/storyOverview" */))
const _cd08153a = () => interopDefault(import('../pages/storeOverview.vue' /* webpackChunkName: "pages/storeOverview" */))
const _f59711d0 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _8f503816 = () => interopDefault(import('../pages/recipe.vue' /* webpackChunkName: "pages/recipe" */))
const _714e7f44 = () => interopDefault(import('../pages/story.vue' /* webpackChunkName: "pages/story" */))
const _25c9f036 = () => interopDefault(import('../pages/page.vue' /* webpackChunkName: "pages/page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _4c55b695,
    name: "homepage___en"
  }, {
    path: "/en/cart",
    component: _1a194f72,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _3eb0c7ed,
    name: "checkout___en"
  }, {
    path: "/en/products",
    component: _4f36d431,
    name: "productOverview___en"
  }, {
    path: "/en/recipes",
    component: _e57bd7e4,
    name: "recipeOverview___en"
  }, {
    path: "/en/stories",
    component: _3bb87777,
    name: "storyOverview___en"
  }, {
    path: "/en/where-to-buy",
    component: _cd08153a,
    name: "storeOverview___en"
  }, {
    path: "/en/products/:slug",
    component: _f59711d0,
    name: "product___en"
  }, {
    path: "/en/recipes/:slug",
    component: _8f503816,
    name: "recipe___en"
  }, {
    path: "/en/stories/:slug",
    component: _714e7f44,
    name: "story___en"
  }, {
    path: "/en/:slug",
    component: _25c9f036,
    name: "page___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
