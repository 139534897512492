import { shape, number, integer, arrayOf } from 'vue-types';

import { assetShape } from '@/types/asset';
import { imageTransform } from '@/types/imageTransform';
import { isNullableString } from '@/types/isNullable';

export const imageShape = {
  ...assetShape,
  width: integer(),
  height: integer(),
  focalPoint: arrayOf(number()).def([0.5, 0.5]),
  caption: isNullableString(),
  copyright: isNullableString(),
  transforms: imageTransform,
};

export const image = shape(imageShape).loose;
export const images = arrayOf(image);
