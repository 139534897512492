export default {
  computed: {
    isLink() {
      if (this.$attrs.to || this.$attrs.href) {
        return true;
      }
      return false;
    },
    isExternalLink() {
      return !!this.$attrs.href;
    },
    component() {
      if (this.$attrs.to) {
        return 'nuxt-link';
      }
      if (this.$attrs.href) {
        return 'a';
      }
      return 'button';
    },
  },
};
