export const state = () => ({
  outroStarted: false,
  outroDone: false,
});

export const getters = {
  use(state, getters, rootState, rootGetters) {
    return true;
  },
  done(state, getters) {
    return state.outroDone || !getters.use;
  },
};

export const mutations = {
  outroStarted(state) {
    state.outroStarted = true;
  },
  outroDone(state) {
    state.outroDone = true;
  },
};
