
export default {
  // FYI if auto import is true, just return the component name, no import needed.
  computed: {
    component() {
      return (
        {
          addedToCart: 'AddedToCart',
          videoPlayer: 'VideoModal',
        }[this.$store.state.modals.current] || false
      );
    },
    scrollDepth() {
      return this.$store.state.scroll.depth;
    },
  },
  watch: {
    component(newVal, oldVal) {
      if (newVal && !oldVal) {
        document.body.addEventListener('keyup', this.onKeyUp);
      } else if (!newVal && oldVal) {
        document.body.removeEventListener('keyup', this.onKeyUp);
      }
    },
    scrollDepth() {
      if (this.component === 'AddedToCart') {
        this.$store.commit('modals/hide');
      }
    },
  },
  methods: {
    onKeyUp({ keyCode }) {
      if (keyCode === 27) {
        this.$store.commit('modals/hide');
      }
    },
  },
};
