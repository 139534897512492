
/**
 * Base video component.
 * Uses the videoPlayer mixin for some props/methods/events abstraction,
 * which is also used by e.g. the VimeoPlayer and YoutubePlayer components.
 *
 * TODO: maybe wrap in a div and use the BasePicture component for the poster,
 * in order to enable responsive images for the poster.
 */
import { bool } from 'vue-types';

import videoPlayer from '@/mixins/videoPlayer';

export default {
  mixins: [videoPlayer],
  inheritAttrs: false,
  props: {
    cover: bool().def(false),
  },
  computed: {
    videoProps() {
      const {
        src,
        loop = false,
        autoplay = true,
        controlsList = 'nodownload',
        muted = false,
        playsinline = true,
        controls = false,
      } = this.$attrs;

      return {
        src,
        loop,
        muted,
        autoplay,
        playsinline,
        controls,
        controlsList,
        poster: this.posterSrc,
      };
    },
    poster() {
      if (this.$attrs.poster) {
        return this.$first(this.$attrs.poster);
      }
      return null;
    },
    posterSrc() {
      if (this.poster) {
        return this.poster.transforms?.src || this.poster.src;
      }
      return null;
    },
    classes() {
      return {
        cover: this.cover,
        playing: this.playing,
        'has-played': this.hasPlayed,
      };
    },
  },
  methods: {
    togglePlayback() {
      if (this.$el.paused) {
        this.play();
      } else {
        this.pause();
      }
    },
    play() {
      this.$el.play();
    },
    pause() {
      this.$el.pause();
    },
    seek(time, resumePlayback = true) {
      this.$el.currentTime = time;

      if (resumePlayback && !this.playing) {
        this.play();
      }
    },

    // --- EVENT HANDLERS ---------------------
    onCanPlay() {
      this.$emit('canPlay');
    },
    onLoadedMetaData() {
      this.duration = this.$el.duration;
    },
  },
};
