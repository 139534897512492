export default {
  scrollTriggers: [],
  data() {
    return {
      createScrollTriggersOnMount: true,
    };
  },
  beforeDestroy() {
    // wait a bit before killing scrolltriggers
    // this way we won't see visible jumping of elements just before the page transition overlay comes in
    setTimeout(() => {
      this.killScrollTriggerTweens();
    }, 200);
  },
  mounted() {
    if (this.createScrollTriggersOnMount) {
      this.$nextTick(() => {
        try {
          this.setupScrollTriggers();
        } catch (error) {
          console.error('Could not create scrolltriggers', error);
        }
      });
    }
  },
  methods: {
    addScrollTrigger(tween) {
      this.$options.scrollTriggers.push(tween);
    },
    killScrollTriggers() {
      this.killScrollTriggerTweens();
    },
    killScrollTriggerTweens() {
      if (this.$options.scrollTriggers?.length) {
        this.$options.scrollTriggers.forEach(this.killScrollTriggerTween);
        this.$options.scrollTriggers = [];
      }
    },
    killScrollTriggerTween(tween) {
      tween?.scrollTrigger?.kill();
      // the actual tween/timeline
      tween?.kill();
    },
    refreshScrollTriggers() {
      if (this.$options.scrollTriggers?.length) {
        this.$options.scrollTriggers.forEach((tween) => {
          tween.scrollTrigger?.refresh();
        });
      }
    },
  },
};
