import unique from '@/utils/array/unique';
import { CDN_URL } from '@/constants/CDN';

const first = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[0];
};

const last = (arr) => {
  if (!arr) return null;
  if (!Array.isArray(arr)) return arr;
  if (!arr.length) return null;

  return arr[arr.length - 1];
};

const pathFromUrl = (url) => {
  if (!url) {
    return '#';
  }

  if (URL) {
    return new URL(url).pathname;
  }
  return url.split('/').slice(3, url.length).join('/');
};

const slugify = (str) => str.toLowerCase().replace(/ /g, '-').normalize('NFC');

const cdnUrl = (filePath) => `${CDN_URL}${filePath}`;

export default ({ i18n }, inject) => {
  // Inject in Vue, context and store.
  inject('first', first);
  inject('last', last);
  inject('unique', unique);
  inject('cdnUrl', cdnUrl);
  inject('pathFromUrl', pathFromUrl);
  inject('slugify', slugify);
  inject('asCurrency', (price) => {
    if (!price || typeof price !== 'number') {
      return price;
    }

    const subZero = price < 0;
    const absPrice = Math.abs(price);
    const localePrice = absPrice.toLocaleString(i18n.locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return `${subZero ? '-' : ''}€${localePrice}`;
  });
};
