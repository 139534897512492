import { string, arrayOf, shape } from 'vue-types';
import { isNullableString } from '@/types/isNullable';
import { entries } from '~/types/entry';

export const customLink = {
  type: string(),
  label: isNullableString(),
  locationUrl: isNullableString(),
  locationEntry: entries,
};

export const customLinkShape = shape(customLink).loose;
export const customLinkArray = arrayOf(customLinkShape);
