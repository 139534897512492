import { render, staticRenderFns } from "./CloseButton.vue?vue&type=template&id=6d5c222b"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/home/runner/work/tomasu-website/tomasu-website/app/components/buttons/IconButton.vue').default})
