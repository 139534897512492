/**
 * Generic interface for video players.
 *
 * Events: 'play' | 'pause'
 */
export default {
  data() {
    return {
      player: null,
      playing: false,
      hasPlayed: false,
      currentTime: 0,
    };
  },
  methods: {
    play() {
      this.player?.play();
    },
    pause() {
      this.player?.pause();
    },
    togglePlayback() {
      if (this.playing) {
        this.pause();
      } else {
        this.play();
      }
    },

    // --- PLAYER EVENTS ------------------------------------------------------
    onPlay() {
      this.playing = true;
      this.hasPlayed = true;
      this.$emit('play');
    },
    onPause() {
      this.playing = false;
      this.$emit('pause');
    },
    onEnd(e) {
      this.playing = false;
    },
    onError(e) {
      console.error(e);
    },
    onTimeUpdate(e) {
      this.currentTime = this.$refs.video?.currentTime;
    },
  },
};
