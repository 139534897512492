export const compareCarts = (currentCart, newCart) => {
  const currentLineItems = currentCart?.lineItems || [];
  const newLineItems = newCart?.lineItems || [];

  if (newLineItems.length !== currentLineItems.length) {
    return false;
  } else {
    for (let i = 0; i < newLineItems.length; i++) {
      const old = currentLineItems.find(({ id }) => id === newLineItems[i].id);
      if (!old || old.qty !== newLineItems[i].qty) {
        return false;
      }
    }
  }

  return true;
};
