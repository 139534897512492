import { GraphQLClient } from 'graphql-request';

export default ({ app, route, req, beforeNuxtRender, $config }) => {
  // Setup HTTP link
  const previewToken = route?.query?.token || null;
  const apiUrl = process.client ? $config.clientApiHost : $config.serverApiHost;

  const apiEndpoint = previewToken
    ? `${apiUrl}/actions/graphql/api?token=${previewToken}`
    : `${apiUrl}/actions/graphql/api`;

  const graphQLClient = new GraphQLClient(apiEndpoint, {
    mode: 'cors',
  });

  // Allow access to the provider in the context
  app.graphQLClient = graphQLClient;
};
