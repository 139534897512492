export const state = () => ({
  current: null,
});

export const mutations = {
  show(state, id) {
    state.current = id;
  },
  hide(state) {
    state.current = null;
  },
};
