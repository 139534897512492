/**
 * Keeping track of scroll depth.
 * Used for analytics.
 */

// define which scroll dephts (percentages) to track.
const DEPTHS_TO_TRACK = [25, 50, 75, 100].reverse();

function getTrackable(scrollValue) {
  for (let i = 0; i < DEPTHS_TO_TRACK.length; i++) {
    const trackable = DEPTHS_TO_TRACK[i];
    if (scrollValue >= trackable) {
      return trackable;
    }
  }
  return null;
}

export const state = () => ({
  locked: false,
  depth: 0,
  tracklist: [],
});

export const mutations = {
  lockTracking(state) {
    state.locked = true;
  },
  unlockTracking(state) {
    state.locked = false;
  },
  /**
   * Set the scroll depth, as a percentage
   */
  update(state, value) {
    if (!state.locked) {
      state.depth = value;

      const trackable = getTrackable(value);
      if (trackable) {
        if (!state.tracklist.includes(trackable)) {
          state.tracklist = [...state.tracklist, trackable];
        }
      }
    } else {
      console.warn('scroll state locked, ignoring update');
    }
  },
  resetTracked(state) {
    state.tracklist = [];
  },
};
