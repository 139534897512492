export default {
  computed: {
    linkComponent() {
      const { type } = this.$first(this.link) || {};
      if (type === 'internal') {
        return 'nuxt-link';
      }
      return 'a';
    },
    linkProps() {
      if (!this.link || !this.link.length) {
        return;
      }

      const { type, locationEntry, locationUrl, label } = this.$first(this.link) || {};
      if (type === 'internal') {
        const { url, title } = this.$first(locationEntry) || {};
        return {
          to: this.$pathFromUrl(url) || '/',
          label: label || title,
        };
      } else {
        return {
          target: '_blank',
          rel: 'noopener',
          href: locationUrl,
          label,
        };
      }
    },
  },
};
