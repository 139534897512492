export default function ({ route, store }) {
  const isNewRoute = route.name !== store.state.route.name;

  // close any modals
  store.commit('modals/hide');

  // close menu overlay
  store.commit('closeMenu');

  // reset scroll depth tracking before routing
  // make sure to unlock after routing
  if (isNewRoute) {
    store.commit('scroll/lockTracking');
    store.commit('scroll/resetTracked');
  }
}
