import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=d3c9792a&scoped=true"
import script from "./SiteFooter.vue?vue&type=script&lang=js"
export * from "./SiteFooter.vue?vue&type=script&lang=js"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=d3c9792a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c9792a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/home/runner/work/tomasu-website/tomasu-website/app/components/icons/Icon.vue').default,FooterBlockHeading: require('/home/runner/work/tomasu-website/tomasu-website/app/components/text/FooterBlockHeading.vue').default,NewsLetterSubscribeForm: require('/home/runner/work/tomasu-website/tomasu-website/app/components/form/NewsLetterSubscribeForm.vue').default})
