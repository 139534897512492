
import { array, arrayOf, bool, shape, string } from 'vue-types';
import { isNullableString } from '@/types/isNullable';

const linkShape = {
  entry: array(),
  linkLabel: isNullableString(),
};

export default {
  inheritAttrs: false,
  props: {
    linkBlocks: arrayOf(
      shape({
        heading: string(),
        links: arrayOf(
          shape({
            ...linkShape,
          }).loose,
        ),
        productLinks: arrayOf(
          shape({
            ...linkShape,
            toOverviewPage: bool(),
          }).loose,
        ),
      }).loose,
    ),
    socialLinks: arrayOf(
      shape({
        id: string(),
        heading: string(),
        links: arrayOf(
          shape({
            channelType: string(),
            channelUrl: string(),
          }).loose,
        ),
      }).loose,
    ),
    paymentItems: arrayOf(
      shape({
        paymentType: string(),
        externalUrl: isNullableString(),
      }).loose,
    ),
    copyright: string(),
  },
  computed: {
    blocks() {
      return this.linkBlocks?.map(this.mapLinkBlock);
    },
    socialLinksBlock() {
      return this.$first(this.socialLinks);
    },
  },
  methods: {
    mapLinkBlock(linkBlock) {
      const { id, heading, links, productLinks } = linkBlock;

      const mappedEntryLinks = links.length ? links.map(this.mapLink).filter((item) => item !== null) : [];
      const mappedProductLinks = productLinks.length
        ? productLinks.map(this.mapLink).filter((item) => item !== null)
        : [];

      // TODO: Filter out possible duplicate section links?

      const blockLinks = [...mappedEntryLinks, ...mappedProductLinks];

      return {
        id,
        heading,
        links: blockLinks,
      };
    },
    mapLink(linkItem) {
      const entry = this.$first(linkItem?.entry);

      if (!entry) {
        return null;
      }

      const label = linkItem.linkLabel || entry.title;
      let to;

      if (linkItem.toOverviewPage === true) {
        to = `${this.localePath('productOverview')}#${this.$slugify(label)}`;
      } else {
        // didn't work using i18n localePath method
        to = this.$pathFromUrl(entry.url);
      }

      return {
        id: linkItem.id,
        label,
        to,
      };
    },
  },
};
