
import { arrayOf, shape, string } from 'vue-types';
import { entries } from '~/types/entry';
import { isNullableString } from '~/types/isNullable';
export default {
  props: {
    items: arrayOf(
      shape({
        id: string().isRequired,
        label: isNullableString(),
        page: entries,
      }).loose,
    ).def([]),
  },
  computed: {
    visible() {
      return this.$store.state.showMenu;
    },
  },
};
