export default {
  mounted() {
    this.scrollSmoother = this.createScrollSmoother();
    this.addScrollListeners();
    this.setupResizeObserver();
  },
  methods: {
    createScrollSmoother() {
      return this.$ScrollSmoother.create({
        smooth: 0.75, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: false, // looks for data-speed and data-lag attributes on elements
        normalizeScroll: false, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
        ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
        onUpdate: () => {
          this.storeScrollPosition();
        },
      });
    },
    addScrollListeners() {
      this.$nuxt.$on('stopScroll', () => {
        this.scrollSmoother.paused(true);
      });

      this.$nuxt.$on('startScroll', () => {
        this.scrollSmoother.paused(false);
        this.$ScrollTrigger.refresh();
      });

      this.$nuxt.$on('resetScroll', () => {
        this.scrollSmoother.scrollTop(0);
      });

      this.$nuxt.$on('scrollTo', ({ target, smooth = true, position = '' }) => {
        if (smooth) {
          this.$gsap.to(this.scrollSmoother, {
            scrollTop: this.scrollSmoother.offset(target, position),
            ease: 'power2.inOut',
            duration: 1,
          });
        } else {
          this.scrollSmoother.scrollTo(target, false, position);
        }
      });

      this.$nuxt.$on('refreshScroll', this.$ScrollTrigger.refresh);
    },
    async setupResizeObserver() {
      if ('ResizeObserver' in window === false) {
        // Loads polyfill asynchronously, only if required.
        const module = await import('@juggle/resize-observer');
        window.ResizeObserver = module.ResizeObserver;
      }
      // Uses native or polyfill, depending on browser support.
      const ro = new ResizeObserver((entries, observer) => {
        this.$ScrollTrigger.sort();
        this.$ScrollTrigger.refresh();
      });
      ro.observe(document.querySelector('#smooth-content'));
    },
    storeScrollPosition() {
      if (!this.scrollSmoother) {
        return;
      }
      const scrollProgress = this.scrollSmoother.progress * 100;

      if (scrollProgress) {
        this.$store.commit('scroll/update', Math.round(scrollProgress));
      }
    },
  },
};
