import { string, number, arrayOf, shape } from 'vue-types';
import { isNullableNumber } from '@/types/isNullable';

export const imageTransformShape = {
  __typename: string(),
  src: string(),
  srcset: string(),
  srcWebp: string(),
  srcsetWebp: string(),
  maxSrcsetWidth: number(),
  placeholderImage: string(),
  color: arrayOf(string()),
  lightness: isNullableNumber(),
};

export const imageTransform = shape(imageTransformShape);
