import { number, object, oneOfType, string } from 'vue-types';

// const nullableType = {
//   type: null,
//   validator(value) {
//     return value === null;
//   },
// };

/**
 * Note: this compiles ok, but throws runtime errors.
 */
function isNullable(propType) {
  return oneOfType([propType(), null]);
}

// export default function isNullable(propType) {
//   return oneOfType([propType, nullableType]);
// }

export function isNullableString() {
  return isNullable(string);
}

export function isNullableNumber() {
  return isNullable(number);
}

export function isNullableObject() {
  return isNullable(object);
}
