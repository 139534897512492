import { string, number, oneOf, oneOfType, shape } from 'vue-types';

export const assetTypes = ['image', 'video'];

export const assetShape = {
  __typename: string(),
  id: oneOfType([string(), number()]),
  siteId: number(),
  title: string(),
  kind: oneOf(assetTypes),
  src: string(),
  mimeType: string(),
};

export const asset = shape(assetShape);
