
import { string } from 'vue-types';
import customLink from '~/mixins/customLink';
import { customLinkArray } from '~/types/customLink';
import { isNullableString } from '~/types/isNullable';
import { visualList } from '~/types/visual';
export default {
  mixins: [customLink],
  inheritAttrs: false,
  props: {
    title: string(),
    tagline: isNullableString(),
    body: string(),
    link: customLinkArray,
    visual: visualList,
  },
};
