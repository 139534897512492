import { render, staticRenderFns } from "./ValidationIconSA.vue?vue&type=template&id=6614bb2a&scoped=true"
import script from "./ValidationIconSA.vue?vue&type=script&lang=js"
export * from "./ValidationIconSA.vue?vue&type=script&lang=js"
import style0 from "./ValidationIconSA.vue?vue&type=style&index=0&id=6614bb2a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6614bb2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/home/runner/work/tomasu-website/tomasu-website/app/components/icons/Icon.vue').default})
