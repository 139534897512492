
// minimum amount of time (in ms) that the preload overlay should wait before starting the outro animation
const INITIAL_DELAY = 250;

export default {
  data() {
    return {
      loadProgress: 0,
    };
  },
  computed: {
    isDev() {
      return this.$store.getters.isDev;
    },
    isReady() {
      return this.$store.state.isReady;
    },
    outroDone() {
      return this.$store.state.preloader.outroDone;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.playAnimation();
    });

    if (this.isDocumentReady()) {
      this.$store.commit('isReady');
    } else {
      document.addEventListener('readystatechange', this.readyStateChangeHandler);
    }
  },
  beforeDestroy() {
    document.removeEventListener('readystatechange', this.readyStateChangeHandler);
  },
  methods: {
    isDocumentReady() {
      return document.readyState === 'complete';
    },
    readyStateChangeHandler() {
      if (this.isDocumentReady()) {
        this.$store.commit('isReady');
      }
    },
    playAnimation() {
      const tl = this.$gsap.timeline({
        delay: INITIAL_DELAY / 1000,
        onStart: () => {
          this.$store.commit('preloader/outroStarted');
        },
        onComplete: () => {
          this.$store.commit('preloader/outroDone');
          this.$nuxt.$emit('pageIntro');
        },
      });

      tl.to(this.$el, { clipPath: 'inset(0 0 0 100%)', ease: 'e1', duration: 0.5 });

      if (this.$refs.logo?.$el) {
        tl.to(this.$refs.logo.$el, { x: '2.5vw', ease: 'e1', duration: 0.5 }, 0);
      }
    },
  },
};
