import Vue from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
// import { SplitText } from 'gsap/SplitText';
import { CustomEase } from 'gsap/CustomEase';

import Easings from '@/constants/Easings';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, CustomEase);

for (const key in Easings) {
  CustomEase.create(key, Easings[key]);
}

// gsap.defaults({
//   ease: 'e1',
// });

const GSAP = {
  install(Vue, options) {
    Vue.prototype.$gsap = gsap;
    Vue.prototype.$ScrollTrigger = ScrollTrigger;
    Vue.prototype.$ScrollSmoother = ScrollSmoother;
  },
};

Vue.use(GSAP);
