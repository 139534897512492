
export default {
  data() {
    return {
      formValues: {
        name: '',
        email: '',
      },
      isValid: true,
      done: false,
      errorMessage: null,
    };
  },
  mounted() {
    if (this.user) {
      const { email } = this.user;

      this.formValues = {
        ...this.formValues,
        email,
      };
    }
  },
  methods: {
    checkValidation() {
      this.done = false;
      if (this.$refs.form) {
        this.isValid = this.$refs.form.checkValidity();
      }
    },
    submitSuccess() {
      this.done = true;
    },
    submitFailure(errorObject, exception) {
      this.errorMessage = this.$t('footer.subscribe.error');

      if (this.$config.environment === 'development') {
        console.error(exception);
        console.error(errorObject);
      } else {
        this.$sentry.captureException(exception);
      }
    },
    async onSubmit(e) {
      e.preventDefault();
      if (this.done) {
        return;
      }

      try {
        const { success, error } = await this.$put('/craft-subscribe/subscribe', {
          name: this.formValues.name,
          email_address: this.formValues.email,
          status: 'subscribed',
        });

        if (success) {
          this.submitSuccess();
        } else {
          this.submitFailure(error);
        }
      } catch (e) {
        this.submitFailure(e.response?.data?.error, e);
      }
    },
  },
};
