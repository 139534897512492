export const Accordion = () => import('../../components/accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccordionItem = () => import('../../components/accordion/AccordionItem.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c))
export const ArrowButton = () => import('../../components/buttons/ArrowButton.vue' /* webpackChunkName: "components/arrow-button" */).then(c => wrapFunctional(c.default || c))
export const CloseButton = () => import('../../components/buttons/CloseButton.vue' /* webpackChunkName: "components/close-button" */).then(c => wrapFunctional(c.default || c))
export const EditButton = () => import('../../components/buttons/EditButton.vue' /* webpackChunkName: "components/edit-button" */).then(c => wrapFunctional(c.default || c))
export const IconButton = () => import('../../components/buttons/IconButton.vue' /* webpackChunkName: "components/icon-button" */).then(c => wrapFunctional(c.default || c))
export const MenuButton = () => import('../../components/buttons/MenuButton.vue' /* webpackChunkName: "components/menu-button" */).then(c => wrapFunctional(c.default || c))
export const PlayPauseButton = () => import('../../components/buttons/PlayPauseButton.vue' /* webpackChunkName: "components/play-pause-button" */).then(c => wrapFunctional(c.default || c))
export const VButton = () => import('../../components/buttons/VButton.vue' /* webpackChunkName: "components/v-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/cards/BaseCard.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/cards/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/cards/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/cart/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartOverview = () => import('../../components/cart/CartOverview.vue' /* webpackChunkName: "components/cart-overview" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartAside = () => import('../../components/cart/CheckoutCartAside.vue' /* webpackChunkName: "components/checkout-cart-aside" */).then(c => wrapFunctional(c.default || c))
export const AddressDetailsBlock = () => import('../../components/form/AddressDetailsBlock.vue' /* webpackChunkName: "components/address-details-block" */).then(c => wrapFunctional(c.default || c))
export const AddressDetailsInline = () => import('../../components/form/AddressDetailsInline.vue' /* webpackChunkName: "components/address-details-inline" */).then(c => wrapFunctional(c.default || c))
export const AddressGroup = () => import('../../components/form/AddressGroup.vue' /* webpackChunkName: "components/address-group" */).then(c => wrapFunctional(c.default || c))
export const CheckoutFormStep = () => import('../../components/form/CheckoutFormStep.vue' /* webpackChunkName: "components/checkout-form-step" */).then(c => wrapFunctional(c.default || c))
export const CheckoutFormSteps = () => import('../../components/form/CheckoutFormSteps.vue' /* webpackChunkName: "components/checkout-form-steps" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepEmail = () => import('../../components/form/CheckoutStepEmail.vue' /* webpackChunkName: "components/checkout-step-email" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepPayment = () => import('../../components/form/CheckoutStepPayment.vue' /* webpackChunkName: "components/checkout-step-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepShipping = () => import('../../components/form/CheckoutStepShipping.vue' /* webpackChunkName: "components/checkout-step-shipping" */).then(c => wrapFunctional(c.default || c))
export const FormControl = () => import('../../components/form/FormControl.vue' /* webpackChunkName: "components/form-control" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/form/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormZipInput = () => import('../../components/form/FormZipInput.vue' /* webpackChunkName: "components/form-zip-input" */).then(c => wrapFunctional(c.default || c))
export const NewsLetterSubscribeForm = () => import('../../components/form/NewsLetterSubscribeForm.vue' /* webpackChunkName: "components/news-letter-subscribe-form" */).then(c => wrapFunctional(c.default || c))
export const Stepper = () => import('../../components/form/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c))
export const PageTransitionOverlay = () => import('../../components/global/PageTransitionOverlay.vue' /* webpackChunkName: "components/page-transition-overlay" */).then(c => wrapFunctional(c.default || c))
export const Preloader = () => import('../../components/global/Preloader.vue' /* webpackChunkName: "components/preloader" */).then(c => wrapFunctional(c.default || c))
export const SiteFooter = () => import('../../components/global/SiteFooter.vue' /* webpackChunkName: "components/site-footer" */).then(c => wrapFunctional(c.default || c))
export const MenuOverlay = () => import('../../components/global/__MenuOverlay.vue' /* webpackChunkName: "components/menu-overlay" */).then(c => wrapFunctional(c.default || c))
export const CardGrid = () => import('../../components/grids/CardGrid.vue' /* webpackChunkName: "components/card-grid" */).then(c => wrapFunctional(c.default || c))
export const GridBody = () => import('../../components/grids/GridBody.vue' /* webpackChunkName: "components/grid-body" */).then(c => wrapFunctional(c.default || c))
export const GridHeading = () => import('../../components/grids/GridHeading.vue' /* webpackChunkName: "components/grid-heading" */).then(c => wrapFunctional(c.default || c))
export const GridVisual = () => import('../../components/grids/GridVisual.vue' /* webpackChunkName: "components/grid-visual" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/header/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const JourneyHeader = () => import('../../components/header/JourneyHeader.vue' /* webpackChunkName: "components/journey-header" */).then(c => wrapFunctional(c.default || c))
export const Checkmark = () => import('../../components/icons/Checkmark.vue' /* webpackChunkName: "components/checkmark" */).then(c => wrapFunctional(c.default || c))
export const Chevron = () => import('../../components/icons/Chevron.vue' /* webpackChunkName: "components/chevron" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/icons/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const ValidationIconSA = () => import('../../components/icons/ValidationIconSA.vue' /* webpackChunkName: "components/validation-icon-s-a" */).then(c => wrapFunctional(c.default || c))
export const BasePicture = () => import('../../components/media/BasePicture.vue' /* webpackChunkName: "components/base-picture" */).then(c => wrapFunctional(c.default || c))
export const BaseVideo = () => import('../../components/media/BaseVideo.vue' /* webpackChunkName: "components/base-video" */).then(c => wrapFunctional(c.default || c))
export const BaseVisual = () => import('../../components/media/BaseVisual.vue' /* webpackChunkName: "components/base-visual" */).then(c => wrapFunctional(c.default || c))
export const VideoControls = () => import('../../components/media/VideoControls.vue' /* webpackChunkName: "components/video-controls" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/media/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VimeoPlayer = () => import('../../components/media/VimeoPlayer.vue' /* webpackChunkName: "components/vimeo-player" */).then(c => wrapFunctional(c.default || c))
export const YoutubePlayer = () => import('../../components/media/YoutubePlayer.vue' /* webpackChunkName: "components/youtube-player" */).then(c => wrapFunctional(c.default || c))
export const AddedToCart = () => import('../../components/modals/AddedToCart.vue' /* webpackChunkName: "components/added-to-cart" */).then(c => wrapFunctional(c.default || c))
export const CartModalItem = () => import('../../components/modals/CartModalItem.vue' /* webpackChunkName: "components/cart-modal-item" */).then(c => wrapFunctional(c.default || c))
export const CookieConsent = () => import('../../components/modals/CookieConsent.vue' /* webpackChunkName: "components/cookie-consent" */).then(c => wrapFunctional(c.default || c))
export const Modals = () => import('../../components/modals/Modals.vue' /* webpackChunkName: "components/modals" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/modals/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/modals/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const VideoModal = () => import('../../components/modals/VideoModal.vue' /* webpackChunkName: "components/video-modal" */).then(c => wrapFunctional(c.default || c))
export const AccordionModule = () => import('../../components/modules/AccordionModule.vue' /* webpackChunkName: "components/accordion-module" */).then(c => wrapFunctional(c.default || c))
export const AnchorSection = () => import('../../components/modules/AnchorSection.vue' /* webpackChunkName: "components/anchor-section" */).then(c => wrapFunctional(c.default || c))
export const ContentGrid = () => import('../../components/modules/ContentGrid.vue' /* webpackChunkName: "components/content-grid" */).then(c => wrapFunctional(c.default || c))
export const Highlight = () => import('../../components/modules/Highlight.vue' /* webpackChunkName: "components/highlight" */).then(c => wrapFunctional(c.default || c))
export const Intro = () => import('../../components/modules/Intro.vue' /* webpackChunkName: "components/intro" */).then(c => wrapFunctional(c.default || c))
export const Modules = () => import('../../components/modules/Modules.vue' /* webpackChunkName: "components/modules" */).then(c => wrapFunctional(c.default || c))
export const ProductGrid = () => import('../../components/modules/ProductGrid.vue' /* webpackChunkName: "components/product-grid" */).then(c => wrapFunctional(c.default || c))
export const Quote = () => import('../../components/modules/Quote.vue' /* webpackChunkName: "components/quote" */).then(c => wrapFunctional(c.default || c))
export const RecipeGrid = () => import('../../components/modules/RecipeGrid.vue' /* webpackChunkName: "components/recipe-grid" */).then(c => wrapFunctional(c.default || c))
export const RichText = () => import('../../components/modules/RichText.vue' /* webpackChunkName: "components/rich-text" */).then(c => wrapFunctional(c.default || c))
export const StoryGrid = () => import('../../components/modules/StoryGrid.vue' /* webpackChunkName: "components/story-grid" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayerModule = () => import('../../components/modules/VideoPlayerModule.vue' /* webpackChunkName: "components/video-player-module" */).then(c => wrapFunctional(c.default || c))
export const Visual = () => import('../../components/modules/Visual.vue' /* webpackChunkName: "components/visual" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/nav/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/nav/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Nav = () => import('../../components/nav/Nav.vue' /* webpackChunkName: "components/nav" */).then(c => wrapFunctional(c.default || c))
export const NavMobile = () => import('../../components/nav/NavMobile.vue' /* webpackChunkName: "components/nav-mobile" */).then(c => wrapFunctional(c.default || c))
export const PortableSubNav = () => import('../../components/nav/PortableSubNav.vue' /* webpackChunkName: "components/portable-sub-nav" */).then(c => wrapFunctional(c.default || c))
export const SubNav = () => import('../../components/nav/SubNav.vue' /* webpackChunkName: "components/sub-nav" */).then(c => wrapFunctional(c.default || c))
export const NewsBar = () => import('../../components/news/NewsBar.vue' /* webpackChunkName: "components/news-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductContentBar = () => import('../../components/product/ProductContentBar.vue' /* webpackChunkName: "components/product-content-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/product/ProductInfo.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductSpotLight = () => import('../../components/product/ProductSpotLight.vue' /* webpackChunkName: "components/product-spot-light" */).then(c => wrapFunctional(c.default || c))
export const ProductVisuals = () => import('../../components/product/ProductVisuals.vue' /* webpackChunkName: "components/product-visuals" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../components/product/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/progress/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const SlideshowProgress = () => import('../../components/progress/SlideshowProgress.vue' /* webpackChunkName: "components/slideshow-progress" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/skeletons/SkeletonLoader.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const ClusterMarker = () => import('../../components/store-locator/ClusterMarker.vue' /* webpackChunkName: "components/cluster-marker" */).then(c => wrapFunctional(c.default || c))
export const LocationMarker = () => import('../../components/store-locator/LocationMarker.vue' /* webpackChunkName: "components/location-marker" */).then(c => wrapFunctional(c.default || c))
export const StoreList = () => import('../../components/store-locator/StoreList.vue' /* webpackChunkName: "components/store-list" */).then(c => wrapFunctional(c.default || c))
export const StoreMap = () => import('../../components/store-locator/StoreMap.vue' /* webpackChunkName: "components/store-map" */).then(c => wrapFunctional(c.default || c))
export const BareTemplate = () => import('../../components/templates/BareTemplate.vue' /* webpackChunkName: "components/bare-template" */).then(c => wrapFunctional(c.default || c))
export const BaseTemplate = () => import('../../components/templates/BaseTemplate.vue' /* webpackChunkName: "components/base-template" */).then(c => wrapFunctional(c.default || c))
export const CardOverview = () => import('../../components/templates/CardOverview.vue' /* webpackChunkName: "components/card-overview" */).then(c => wrapFunctional(c.default || c))
export const JourneyTemplate = () => import('../../components/templates/JourneyTemplate.vue' /* webpackChunkName: "components/journey-template" */).then(c => wrapFunctional(c.default || c))
export const Order = () => import('../../components/templates/Order.vue' /* webpackChunkName: "components/order" */).then(c => wrapFunctional(c.default || c))
export const StoreOverview = () => import('../../components/templates/StoreOverview.vue' /* webpackChunkName: "components/store-overview" */).then(c => wrapFunctional(c.default || c))
export const AgeingInfo = () => import('../../components/text/AgeingInfo.vue' /* webpackChunkName: "components/ageing-info" */).then(c => wrapFunctional(c.default || c))
export const Body = () => import('../../components/text/Body.vue' /* webpackChunkName: "components/body" */).then(c => wrapFunctional(c.default || c))
export const CardLabel = () => import('../../components/text/CardLabel.vue' /* webpackChunkName: "components/card-label" */).then(c => wrapFunctional(c.default || c))
export const CartCount = () => import('../../components/text/CartCount.vue' /* webpackChunkName: "components/cart-count" */).then(c => wrapFunctional(c.default || c))
export const FooterBlockHeading = () => import('../../components/text/FooterBlockHeading.vue' /* webpackChunkName: "components/footer-block-heading" */).then(c => wrapFunctional(c.default || c))
export const PriceLabel = () => import('../../components/text/PriceLabel.vue' /* webpackChunkName: "components/price-label" */).then(c => wrapFunctional(c.default || c))
export const ProductInfoLabel = () => import('../../components/text/ProductInfoLabel.vue' /* webpackChunkName: "components/product-info-label" */).then(c => wrapFunctional(c.default || c))
export const ShippingInfo = () => import('../../components/text/ShippingInfo.vue' /* webpackChunkName: "components/shipping-info" */).then(c => wrapFunctional(c.default || c))
export const SiteTitle = () => import('../../components/text/SiteTitle.vue' /* webpackChunkName: "components/site-title" */).then(c => wrapFunctional(c.default || c))
export const Title = () => import('../../components/text/Title.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
