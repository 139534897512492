
import { oneOf, bool, string } from 'vue-types';

import hasScrollTriggers from '@/mixins/hasScrollTriggers';

const TYPE_IMG = 'image';
const TYPE_VIDEO = 'video';

export default {
  mixins: [hasScrollTriggers],
  inheritAttrs: false,
  props: {
    kind: oneOf([TYPE_IMG, TYPE_VIDEO]).def(TYPE_IMG),
    useParallax: bool().def(false),
    full: bool().def(false),
    useCopyright: bool().def(true),
    showOverlay: bool().def(false),
    horizontal: bool().def(false),
    transformsKey: string().def('transforms'),
  },
  data() {
    return {
      isPlaying: false,
    };
  },
  computed: {
    isVideo() {
      return this.kind === TYPE_VIDEO;
    },
    classes() {
      return [this.useParallax ? 'parallax' : null];
    },
    renderOverlay() {
      return this.showOverlay && this.isVideo;
    },
    renderVideoControls() {
      return this.showOverlay && this.isVideo;
    },
    /**
     * If the Visual renders a video and should open the actual video inside the Video Overlay portal,
     * then the video rendered here is considered to be a small looping video.
     */
    videoProps() {
      const props = {
        ...this.$attrs,
      };

      return props;
    },
    transforms() {
      return this.$attrs[this.transformsKey] || this.$attrs.transforms || null;
    },
    intrinsicAspectRatio() {
      return this.$attrs.width / this.$attrs.height;
    },
  },
  methods: {
    onVideoPlay() {
      this.isPlaying = true;
    },
    onVideoPause() {
      this.isPlaying = false;
    },
    onPlayPauseClick() {
      this.playVideo();
    },
    onVideoClick() {
      this.toggleVideoPlayback();
    },
    playVideo() {
      this.$refs.video?.play();
    },
    pauseVideo() {
      this.$refs.video?.pause();
    },
    toggleVideoPlayback() {
      this.$refs.video?.togglePlayback();
    },
    setupScrollTriggers() {
      if (this.useParallax) {
        this.addParallax();
      }

      this.addReveal();
    },
    addReveal() {
      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
        },
      });

      tl.fromTo(
        this.$refs.content,
        {
          scale: 1.1,
        },
        {
          scale: 1,
          ease: 'e2',
        },
      );

      this.addScrollTrigger(tl);
    },
    addParallax() {
      if (!this.$refs.content) {
        return;
      }

      const tl = this.$gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
        },
      });

      const prop = this.horizontal ? 'xPercent' : 'yPercent';

      tl.fromTo(
        this.$refs.content,
        {
          [prop]: -10,
        },
        {
          [prop]: 10,
          ease: 'none',
        },
        0,
      );

      this.addScrollTrigger(tl);
    },
  },
};
