import { render, staticRenderFns } from "./error.vue?vue&type=template&id=014b9250&scoped=true"
import script from "./error.vue?vue&type=script&lang=js"
export * from "./error.vue?vue&type=script&lang=js"
import style0 from "./error.vue?vue&type=style&index=0&id=014b9250&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "014b9250",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BareTemplate: require('/home/runner/work/tomasu-website/tomasu-website/app/components/templates/BareTemplate.vue').default})
