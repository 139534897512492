export default ({ app, store, i18n }, inject) => {
  function getActionPath(path) {
    const url = `/${i18n.locale}/actions${path}`;
    return url;
  }

  function getCsrfToken() {
    if (!store.state.csrfToken?.value) {
      try {
        return app.$axios.$get(getActionPath('/main/session/csrf')).then((token) => {
          if (token.name && token.value) {
            if (process.client) {
              store.commit('csrfToken', token);
            }
            return token;
          }
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      return store.state.csrfToken;
    }
  }

  async function fetch(method, path, payload, params) {
    const url = getActionPath(path);

    if (method === '$get') {
      return app.$axios[method](url, params);
    }

    const token = await getCsrfToken();

    return app.$axios[method](
      url,
      { ...payload, [token.name]: token.value },
      { ...params, headers: { 'X-CSRF-Token': token.value } },
    );
  }

  inject('get', (path, params) => {
    return fetch('$get', path, null, params);
  });

  inject('post', (path, payload, params) => {
    return fetch('$post', path, payload, params);
  });

  inject('put', (path, payload, params) => {
    return fetch('$put', path, payload, params);
  });
};
