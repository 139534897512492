
import { mapGetters } from 'vuex';
import { compareCarts } from '@/utils/session';
import scrollSmoother from '@/mixins/scrollSmoother';

export default {
  mixins: [scrollSmoother],
  computed: {
    ...mapGetters({
      renderPreloader: 'preloader/use',
    }),
    globals() {
      return this.$store.state.globals;
    },
    hasModal() {
      return !!this.$store.state.modals.current;
    },
    hasNews() {
      return this.$store.getters.showNews;
    },
  },
  async mounted() {
    const { user, cart } = await this.$get('/main/session');

    if (this.$route.path.includes('/checkout') && (!cart || !cart.lineItems.length)) {
      this.$router.replace(this.localePath('cart'));
    }

    if (user) {
      this.$store.commit('user', user);
    }

    if (cart) {
      if (this.$store.state.cart && !compareCarts(this.$store.state.cart, cart)) {
        this.$store.dispatch('addNotification', this.$t('notifications.cartChanged'));
      }

      this.$store.commit('cart', cart);
    }
  },
};
