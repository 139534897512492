
import { string, object, bool, oneOf } from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';

export const THEME_DARK = 'dark'; // dark bg, light text
export const THEME_LIGHT = 'light'; // white bg, black text
export const THEME_BRONZE = 'bronze';
export const THEME_NONE = 'none'; // provide own theming

export default {
  mixins: [buttonOrLink],
  inheritAttrs: false,
  props: {
    theme: oneOf([THEME_DARK, THEME_LIGHT, THEME_BRONZE, THEME_NONE]).def(THEME_DARK),
    icon: string(),
    iconProps: object(),
    label: string(), // needed for a11y
    outline: bool().def(true),
  },
  computed: {
    classes() {
      return [
        this.theme === THEME_NONE ? 'theme-none' : this.theme,
        this.outline ? 'border-2' : null,
        this.isLink ? 'link' : null,
      ];
    },
    ariaLabel() {
      return this.label || this.icon ? `btn-${this.icon}` : null;
    },
  },
};
