
import { string, number, bool, arrayOf, shape, oneOfType } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    id: string().required,
    formName: string(),
    type: string().def('text'),
    name: string(),
    label: string(),
    placeholder: string(),
    value: oneOfType([string(), number(), bool()]),
    options: arrayOf(
      shape({
        label: string(),
        value: oneOfType([string(), number(), bool()]),
      }),
    ),
    required: bool().def(false),
    checked: bool().def(false),
    error: string(),
    customErrorMessage: string(),
  },
  data() {
    return {
      liveValue: this.value,
      isValid: true,
      hasFocus: false,
      errorMessage: '',
    };
  },
  computed: {
    fieldName() {
      return this.formName ? `${this.formName}[${this.name}]` : this.name;
    },
    showLabel() {
      return this.label && this.type !== 'select';
    },
    hasValue() {
      return !!this.value;
    },
    hasError() {
      return !this.isValid || (this.error && this.error.length);
    },
  },
  watch: {
    value(val) {
      this.liveValue = val;

      this.isValid = true;
      this.errorMessage = '';
    },
    liveValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    clearSearch(e) {
      console.log('clearSearch');
      e.stopPropagation();
      this.$emit('clear');
      this.liveValue = '';
    },
    onChange() {
      if (this.type === 'search') return;

      if (this.type === 'checkbox' || this.type === 'radio') {
        let isValid = true;
        const errorMessages = [];
        for (const i in this.$refs.field) {
          if (!this.$refs.field[i].validity.valid) isValid = false;
          if (this.$refs.field[i].validationMessage.length) errorMessages.push(this.$refs.field[i].validationMessage);
        }
        this.isValid = isValid;
        this.errorMessage = errorMessages.join(' ');
      } else {
        this.isValid = this.$refs.field.validity.valid;
        this.errorMessage = this.$refs.field.validationMessage;
      }

      this.$emit('change', this.liveValue);
    },
    onFocus() {
      this.hasFocus = true;
    },
    onBlur() {
      this.hasFocus = false;
    },
    onInvalid() {
      // Set custom error message when invalid
      if (this.customErrorMessage?.length && this.type !== 'checkbox' && this.type !== 'radio') {
        this.$refs.field.setCustomValidity(this.customErrorMessage);
      }
    },
    onInput() {
      // Always reset custom error message
      if (this.customErrorMessage?.length && this.type !== 'checkbox' && this.type !== 'radio') {
        this.$refs.field.setCustomValidity('');
      }
    },
  },
};
