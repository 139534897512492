
import { number, shape } from 'vue-types';

export default {
  props: {
    error: shape({
      statusCode: number(),
    }).loose,
  },
  computed: {
    templateProps() {
      const locationEntry = [
        {
          url: `${this.$config.siteUrl}${this.localePath('/')}`,
        },
      ];

      if (this.error.statusCode === 404) {
        return {
          title: this.$t('error.pageNotFound.title'),
          tagline: this.$t('error.pageNotFound.tagline'),
          link: [
            {
              type: 'internal',
              label: this.$t('error.pageNotFound.buttonLabel'),
              locationEntry,
            },
          ],
        };
      }
      return {
        title: this.$t('error.genericError.title'),
        tagline: this.$t('error.genericError.tagline'),
        link: [
          {
            type: 'internal',
            label: this.$t('error.genericError.buttonLabel'),
            locationEntry,
          },
        ],
      };
    },
  },
  mounted() {
    if (this.$config.dev) {
      console.log(this.error);
    }
  },
};
