
export const NOTIFICATION_DURATION = 10000;

export default {
  data() {
    return {
      interval: null,
    };
  },
  computed: {
    notifications() {
      return Object.entries(this.$store.state.notifications).map(([key, value]) => ({ key, value }));
    },
  },
  watch: {
    notifications(value) {
      if (Object.keys(value).length && !this.interval) {
        this.interval = setInterval(this.validateNotifications, NOTIFICATION_DURATION);
      } else if (this.interval) {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    closeNotification(timestamp) {
      this.$store.commit('removeNotification', timestamp);
    },
    validateNotifications() {
      this.notifications.forEach(({ timestamp }) => {
        if (timestamp + NOTIFICATION_DURATION < new Date().getTime()) {
          this.$store.commit('removeNotification', timestamp);
        }
      });
    },
  },
};
