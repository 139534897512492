import { ClientError } from 'graphql-request';

export default ({ app, error }, inject) => {
  inject('cms', {
    async query({ query, variables, mandatoryKeys = [] }) {
      let response;
      try {
        response = await app.graphQLClient.request(query, variables);
      } catch (error) {
        if (error instanceof ClientError) {
          console.error(error.response);
        } else {
          console.error(error);
        }
      }

      let notFound = false;

      if (mandatoryKeys) {
        mandatoryKeys.forEach((key) => {
          if (!response[key]) {
            notFound = true;
          }
        });
      }
      if (notFound) {
        return error({ statusCode: 404, message: 'Not found' });
      }
      return response;
    },
  });
};
