import breakpoints from '@/constants/Breakpoints';
import globalsQuery from '@/gql/queries/globals.gql';
import { NOTIFICATION_DURATION } from '@/components/modals/Notifications.vue';

// TODO: move all cookie related stuff into dedicated vuex module
import {
  COOKIE_CONSENT_FUNCTIONAL,
  COOKIE_CONSENT_ANALYTICS,
  COOKIE_CONSENT_MARKETING,
  COOKIE_CONSENT_NAME,
  COOKIE_CONSENT_UNCHECKED,
  COOKIE_CONSENT_PENDING,
  COOKIE_SETTINGS,
  COOKIE_CONSENT_VERSION,
  COOKIE_CONSENT_SET,
} from '@/constants/Cookies';

const initialCookieConsent = {
  [COOKIE_CONSENT_FUNCTIONAL]: true,
  [COOKIE_CONSENT_ANALYTICS]: false,
  [COOKIE_CONSENT_MARKETING]: false,
};

export const state = () => ({
  user: null,
  cart: null,
  countries: {},
  administrativeAreas: {},
  csrfToken: null,
  isReady: null,
  showMenu: false,
  showNavToggle: false,
  showSubNav: false,
  cookieConsentStatus: COOKIE_CONSENT_UNCHECKED,
  cookieConsent: initialCookieConsent,
  globals: {},
  subscribed: false,
  scrolledPastSubscribe: false,
  latestCartAddition: null,
  newsDismissed: true,
  notifications: {},
});

export const getters = {
  locale(state) {
    if (state.route && state.route.params && state.route.params.locale) {
      return state.route.params.locale.toLowerCase();
    }
    return 'nl';
  },
  site(state, getters) {
    return getters.locale || 'nl';
  },
  queryParams(state) {
    return state.route.query;
  },
  slug(state) {
    return state.route.params.slug;
  },
  isHome(state) {
    if (!state.route.name) return false;
    return state.route.name === 'locale';
  },
  hideHeader(state) {
    if (state.route.name && state.route.name.match(/^checkout/)) {
      return true;
    }
    return false;
  },
  breakpoint: (state) => {
    const vw = state.viewport.width;

    return breakpoints.find(({ value }) => value <= vw);
  },
  trackingProps: (state) => {
    return {
      routeName: state.route.name,
      pageUrl: state.route.fullPath,
    };
  },
  cartTotalProductCount(state) {
    return state.cart?.totalQty || 0;
  },
  showNews(state) {
    return state.globals?.news?.items?.length && !state.newsDismissed;
  },
};

export const mutations = {
  addNotification(state, { timestamp, text }) {
    state.notifications = {
      ...state.notifications,
      [timestamp]: text,
    };
  },
  removeNotification(state, timestamp) {
    const notifications = { ...state.notifications };
    delete notifications[timestamp];
    state.notifications = notifications;
  },
  user(state, user) {
    state.user = user;
  },
  cart(state, cart) {
    state.cart = cart;
  },
  setCountries(state, countries) {
    state.countries = countries;
  },
  setAdministrativeAreas(state, administrativeAreas) {
    state.administrativeAreas = administrativeAreas;
  },
  csrfToken(state, value) {
    state.csrfToken = value;
  },
  csrfTokenValue(state, value) {
    state.csrfToken.value = value;
  },
  logOut(state) {
    state.csrfToken = null;
    state.user = null;
  },
  isReady(state) {
    state.isReady = true;
  },
  showMenu(state) {
    state.showMenu = true;
  },
  showSubNav(state, val) {
    state.showSubNav = !!val;
  },
  closeMenu(state) {
    state.showMenu = false;
  },
  toggleMenu(state) {
    state.showMenu = !state.showMenu;
  },
  setCookieConsentStatus(state, status) {
    state.cookieConsentStatus = status;
  },
  setCookieConsent(state, cookieConsent) {
    state.cookieConsent = {
      ...state.cookieConsent,
      ...cookieConsent,
    };
  },
  dismissNews(state, value = true) {
    state.newsDismissed = !!value;
  },
  setGlobals(state, value) {
    state.globals = value;
  },
  hasSubscribed(state) {
    state.subscribed = true;
  },
  latestCartAddition(state, { product, variant, quantity, totalPrice }) {
    state.latestCartAddition = {
      product,
      variant,
      quantity,
      totalPrice,
    };
  },
};

export const actions = {
  /**
   * Fetch globals and store in vuex store so they can be accessed anywhere.
   */
  async nuxtServerInit({ commit }, { $cms, $get, i18n, error, $sentry }) {
    try {
      const [{ countries, administrativeAreas }, globals] = await Promise.all([
        $get('/main/countries'),
        $cms.query({
          query: globalsQuery,
          variables: { site: i18n.locale },
        }),
      ]);

      commit('setGlobals', globals);
      commit('setCountries', countries);
      commit('setAdministrativeAreas', administrativeAreas);
    } catch (e) {
      if (process.env.ENVIRONMENT === 'development') {
        console.error(e);
      } else {
        $sentry.captureException(e);
      }
      error({ statusCode: 500, message: 'Error during initialization' });
    }
  },
  addNotification({ commit }, text) {
    const timestamp = new Date().getTime();
    commit('addNotification', { timestamp, text });
    if (process.client) {
      setTimeout(() => {
        commit('removeNotification', timestamp);
      }, NOTIFICATION_DURATION);
    }
  },
  getCookieConsent({ commit }) {
    if (process.client) {
      const cookieConsent = this.$cookies.get(COOKIE_CONSENT_NAME);

      if (!cookieConsent || cookieConsent.v !== COOKIE_CONSENT_VERSION) {
        commit('setCookieConsent', initialCookieConsent);
        commit('setCookieConsentStatus', COOKIE_CONSENT_PENDING);
        return;
      }

      delete cookieConsent.v;

      commit('setCookieConsent', cookieConsent);
      commit('setCookieConsentStatus', COOKIE_CONSENT_SET);
    }
  },
  setCookieConsent({ commit, state }, cookieConsent) {
    if (process.client) {
      commit('setCookieConsent', cookieConsent);
      commit('setCookieConsentStatus', COOKIE_CONSENT_SET);

      const cookiePayload = {
        ...state.cookieConsent,
        ...cookieConsent,
        v: COOKIE_CONSENT_VERSION,
      };

      this.$cookies.set(COOKIE_CONSENT_NAME, cookiePayload, COOKIE_SETTINGS);
    }
  },
};
