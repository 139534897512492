import { shape, bool } from 'vue-types';

import { assetShape } from '@/types/asset';
import { images } from '@/types/image';
import { isNullableString } from '@/types/isNullable';

export const videoShape = {
  ...assetShape,
  loop: bool().def(false),
  caption: isNullableString(),
  description: isNullableString(),
  copyright: isNullableString(),
  poster: images,
};

export const video = shape(videoShape).loose;
