
import { bool, string, object } from 'vue-types';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';

import { imageShape } from '@/types/image';

export default {
  inheritAttrs: false,
  props: {
    ...imageShape,
    loading: bool().def(false),
    sizes: object().def({ xs: '100vw' }),
    alt: string(),
    renderPlaceholder: bool().def(true),
    lazy: bool().def(true),
    imgClasses: string(),
    cover: bool().def(false),
    contain: bool().def(false),
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    sizesAttr() {
      return Object.keys(this.sizes)
        .map((breakpoint) => {
          const size =
            typeof this.sizes[breakpoint] === 'number' ? `${this.sizes[breakpoint]}px` : this.sizes[breakpoint];
          return breakpoint === 'xs' ? size : `(min-width: ${breakpoints[breakpoint]}px) ${size}`;
        })
        .reverse()
        .join(', ');
    },
    classes() {
      return {
        cover: this.cover,
        contain: this.contain,
      };
    },
    style() {
      return {
        backgroundImage: this.usePlaceholder ? `url(${this.transforms.placeholderImage})` : null,
      };
    },
    finalImgClasses() {
      return [this.imgClasses, this.loaded ? 'loaded' : null];
    },
    imgStyle() {
      const style = {
        objectPosition: this.hasFocal ? `${this.focalX}% ${this.focalY}%` : null,
      };

      if (!this.cover && !this.contain) {
        style['aspect-ratio'] = `${this.width} / ${this.height}`;
      }

      return style;
    },
    imgSrc() {
      return this.transforms?.src || this.src;
    },
    imgSrcset() {
      return this.transforms?.srcset || this.$attrs.srcset;
    },
    isTransparent() {
      const mimeType = this.mimeType || this.mimeType;
      return mimeType === 'image/png' || mimeType === 'image/svg+xml';
    },
    imgWidth() {
      return this.transforms?.maxSrcsetWidth || this.width;
    },
    usePlaceholder() {
      return this.renderPlaceholder && !this.isTransparent && this.transforms?.placeholderImage;
    },
    focalX() {
      return (this.$first(this.focalPoint) || 0.5) * 100;
    },
    focalY() {
      return (this.$last(this.focalPoint) || 0.5) * 100;
    },
    hasFocal() {
      return !(this.focalX === 50 && this.focalY === 50);
    },
    altText() {
      return this.alt || this.title || '';
    },
  },
  methods: {
    onLoad() {
      this.loaded = true;

      this.$emit('load');
    },
  },
};
