
import { arrayOf, shape, string } from 'vue-types';
import { COOKIE_NEWS_DISMISSED_NAME, COOKIE_SETTINGS } from '~/constants/Cookies';

const INTERVAL = 10000;

export default {
  inheritAttrs: false,
  props: {
    dateUpdated: string(),
    items: arrayOf(
      shape({
        id: string(),
        text: string(),
      }).loose,
    ),
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    dismissed() {
      return this.$store.state.newsDismissed;
    },
    maxIndex() {
      return this.items?.length - 1 || 0;
    },
    show() {
      return this.items?.length > 0 && !this.dismissed;
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.stop();
      }
    },
  },
  beforeDestroy() {
    this.stop();
  },
  mounted() {
    const dismissed = this.$cookies.get(COOKIE_NEWS_DISMISSED_NAME);
    let show = false;

    if (!dismissed) {
      show = true;
    } else {
      const dismissedDate = new Date(dismissed);
      const lastUpdated = new Date(this.dateUpdated);
      show = dismissedDate < lastUpdated;
    }

    this.$store.commit('dismissNews', !show);

    if (show && this.items?.length) {
      this.start();
    }
  },
  methods: {
    start() {
      if (this.maxIndex > 0) {
        this.timeout = setTimeout(() => {
          this.next();
        }, INTERVAL);
      }
    },
    stop() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
    next() {
      let nextIndex = this.currentIndex + 1;
      if (nextIndex > this.maxIndex) {
        nextIndex = 0;
      }
      this.currentIndex = nextIndex;

      this.start();
    },
    onCloseClick() {
      this.$store.commit('dismissNews');
      this.$cookies.set(COOKIE_NEWS_DISMISSED_NAME, this.dateUpdated, COOKIE_SETTINGS);
    },
  },
};
