
import { oneOf, bool } from 'vue-types';

import buttonOrLink from '@/mixins/buttonOrLink';

export const THEME_PRIMARY = 'primary'; // primary bg, light text
export const THEME_DARK = 'dark'; // dark bg, light text
export const THEME_LIGHT = 'light'; // white bg, black text
export const THEME_OUTLINE = 'outline';
export const THEME_PLAIN = 'plain';
export const THEME_NONE = 'none'; // provide own theming

export const SIZE_DEFAULT = 'default';
export const SIZE_LARGE = 'large';

export default {
  mixins: [buttonOrLink],
  inheritAttrs: false,
  props: {
    theme: oneOf([THEME_PRIMARY, THEME_DARK, THEME_LIGHT, THEME_OUTLINE, THEME_PLAIN, THEME_NONE]).def(THEME_DARK),
    size: oneOf([SIZE_DEFAULT, SIZE_LARGE]).def(SIZE_DEFAULT),
    disabled: bool().def(false),
  },
  data() {
    return {
      mouseHover: false,
    };
  },
  computed: {
    classes() {
      return {
        'inner-hover': this.mouseHover,
        [this.theme]: this.theme !== THEME_NONE,
        [this.size]: this.size !== SIZE_DEFAULT,
        link: this.isLink,
        disabled: this.disabled,
      };
    },
    labelClasses() {
      return [this.size === SIZE_LARGE ? 'text-s-fluid--2' : 'text-s-fluid--4'];
    },
    computedProps() {
      if (this.isExternalLink) {
        return {
          ...this.$attrs,
          target: '_blank',
          rel: 'noopener',
        };
      }

      return this.$attrs;
    },
  },
  methods: {
    tweenDown() {
      if (!this.$attrs.disabled) {
        const { button } = this.$refs;
        if (button) {
          this.$gsap.fromTo(button, { scale: 1 }, { scale: 0.9, duration: 0.1 });
        }
      }
    },
    tweenUp() {
      if (!this.$attrs.disabled) {
        const { button } = this.$refs;
        if (button) {
          this.$gsap.to(button, {
            scale: 1,
            duration: 0.8,
            ease: 'back.out(1)',
          });
        }
      }
    },
    mouseLeave() {
      this.mouseHover = false;
      this.tweenUp();
    },
  },
};
