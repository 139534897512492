export const state = () => ({
  width: 0,
  height: 0,
  availWidth: 0,
  availHeight: 0,
});

export const mutations = {
  update(state, { width, height, availWidth, availHeight }) {
    state.width = width || state.width;
    state.height = height || state.height;
    state.availWidth = availWidth || state.availWidth;
    state.availHeight = availHeight || state.availHeight;
  },
};
