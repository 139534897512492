
import { string } from 'vue-types';

import { COOKIE_CONSENT_FUNCTIONAL, COOKIE_CONSENT_PENDING, COOKIE_CONSENT_UNCHECKED } from '~/constants/Cookies';

export default {
  props: {
    heading: string(),
    body: string(),
  },
  computed: {
    show() {
      return this.$store.state.cookieConsentStatus === COOKIE_CONSENT_PENDING;
    },
    compHeading() {
      return this.cookieConsent?.heading || this.heading;
    },
    compBody() {
      return this.cookieConsent?.body || this.body;
    },
  },
  created() {
    if (this.$store.state.cookieConsentStatus === COOKIE_CONSENT_UNCHECKED) {
      this.$store.dispatch('getCookieConsent');
    }
  },
  methods: {
    onClick() {
      this.$store.dispatch('setCookieConsent', { [COOKIE_CONSENT_FUNCTIONAL]: true });
    },
  },
};
