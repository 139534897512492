
import { string, bool, number } from 'vue-types';

export default {
  props: {
    name: string().isRequired,
    fill: bool(),
    size: number(),
    width: number(),
    height: number(),
  },
  data() {
    return {
      icon: () =>
        import(
          /* webpackChunkName: "icons" */
          /* webpackMode: "lazy-once" */
          `@/assets/icons/${this.name}.svg`
        ),
    };
  },
  computed: {
    computedWidth() {
      return this.width || this.size;
    },
    computedHeight() {
      return this.height || this.size;
    },
    hasSize() {
      return this.computedWidth && this.computedHeight;
    },
    classes() {
      return {
        fill: this.fill,
        full: this.hasSize,
      };
    },
    style() {
      if (this.hasSize) {
        return {
          width: `${this.computedWidth}px`,
          height: `${this.computedHeight}px`,
        };
      }
      return null;
    },
  },
  watch: {
    name(newVal, oldVal) {
      this.icon = () =>
        import(
          /* webpackChunkName: "icons" */
          /* webpackMode: "lazy-once" */
          `@/assets/icons/${newVal}.svg`
        );
    },
  },
};
