import { string, number, shape, arrayOf } from 'vue-types';

export const entryShape = {
  __typename: string(),
  id: string(),
  siteId: number(),
  title: string(),
  url: string(),
  slug: string(),
  type: string(),
};

export const entry = shape(entryShape).loose;
export const entries = arrayOf(entry);
